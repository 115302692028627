var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mobile-views-main-component-wrapper" }, [
    _c(
      "div",
      { staticClass: "container-mobile", class: { "mt-16": _vm.$isPwa() } },
      _vm._l(_vm.pos, function (poitem, i) {
        return _c(
          "div",
          { key: i, staticClass: "card" },
          [
            _c("div", { staticClass: "wrapper" }, [
              _c("div", { staticClass: "header" }, [
                _c("div", { staticClass: "first-col" }, [
                  _c("span", [_vm._v(_vm._s(poitem.depName))]),
                ]),
                _c(
                  "div",
                  { staticClass: "second-col d-flex justify-content-end" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "status info px-2",
                        staticStyle: { width: "auto" },
                      },
                      [_c("span", [_vm._v(_vm._s(poitem.numPoForDep))])]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._l(_vm.pos[0].users, function (user, z) {
              return _c(
                "div",
                [
                  _c("validate-expense-detail", {
                    attrs: {
                      user: user,
                      items: user.po,
                      fields: _vm.poFields,
                      "default-toggle-detail": false,
                    },
                    on: {
                      "validate-expense-detail:validated":
                        _vm.handleValidateItem,
                      "validate-expense-detail:clicked": _vm.handleCardClicked,
                    },
                  }),
                ],
                1
              )
            }),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }