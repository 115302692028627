<template>
	<div class="card valide-po-card-item--component-wrapper" @click="handleCardClicked">
		<div class="card-body">
			<div class="head">
				<span :class="['badge badge-' + item.validatedClass]">{{ item.validatedStatus }}</span>
			</div>
			<div class="content">
				<article v-html="item.description" />
				<div>
					<span>
						{{ FormMSG(1, 'Amount') }}: <b>{{ rendAmount }}</b>
					</span>
					<br />
					<span>
						{{ FormMSG(2, 'Supplier') }}: <b>{{ item.supplierName }}</b>
					</span>
				</div>
				<b-button variant="success" @click.prevent.stop="validateItem">
					<i class="icon-check"></i>
				</b-button>
			</div>
			<div class="foot">
				<span v-html="rendRequestDate" class="date" />
			</div>
		</div>
	</div>
</template>

<script>
import { rendCurrency, formatTableDate } from '~helpers';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'MobileViewsValidatePoItemComponent',
	mixins: [languageMessages],
	props: {
		item: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	computed: {
		/**
		 * @return {String}
		 */
		rendAmount() {
			return rendCurrency(this.item.amount);
		},

		rendRequestDate() {
			return formatTableDate(this.item.requestDate);
		}
	},
	methods: {
		handleCardClicked() {
			this.$emit('card-clicked', this.item);
		},

		validateItem() {
			this.$emit('validate-item', this.item);
		}
	}
};
</script>

<style lang="scss" scoped>
.head {
	padding-bottom: 10px;
}
.content {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.foot {
	width: 100%;
	padding-top: 10px;
	display: flex;
	justify-content: flex-end;
}
@media (max-width: 992px) {
	.content {
		flex-flow: column wrap;
		& > * {
			width: 100%;
		}
	}
}
</style>
