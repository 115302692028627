<template>
	<div class="mobile-views-main-component-wrapper">
		<div class="container-mobile" :class="{ 'mt-16': $isPwa() }">
			<!-- <pre>{{ pos }}</pre> -->
			<div class="card" v-for="(poitem, i) in pos" :key="i">
				<div class="wrapper">
					<div class="header">
						<div class="first-col">
							<span>{{ poitem.depName }}</span>
						</div>
						<div class="second-col d-flex justify-content-end">
							<div class="status info px-2" style="width: auto">
								<span>{{ poitem.numPoForDep }}</span>
							</div>
						</div>
					</div>
				</div>
				<div v-for="(user, z) in pos[0].users">
					<validate-expense-detail
						@validate-expense-detail:validated="handleValidateItem"
						@validate-expense-detail:clicked="handleCardClicked"
						:user="user"
						:items="user.po"
						:fields="poFields"
						:default-toggle-detail="false"
					/>
				</div>
			</div>
		</div>
		<!-- <CollapseCard open v-for="(poitem, i) in pos" :key="i" :title="rendCardTitle(poitem)">
			<CollapseCard v-for="(user, y) in poitem.users" open :key="y" :title="rendUserCollapseCardTitle(user)">
				<MobileCardPOItem
					v-for="(po, z) in user.po"
					:key="z"
					:item="po"
					:type="po.type"
					@validate-item="handleValidateItem"
					@card-clicked="handleCardClicked"
				/>
			</CollapseCard>
		</CollapseCard> -->
	</div>
</template>

<script>
import MobileCardPOItem from './Item';
import ValidateExpenseDetail from '../ValidateExpenses/ValidateExpenseDetail';
import languageMessages from '@/mixins/languageMessages';
import moment from 'moment';

export default {
	name: 'MobileViewsValidatePoMainComponent',
	components: { MobileCardPOItem, ValidateExpenseDetail },

	mixins: [languageMessages],
	props: {
		pos: {
			type: Array,
			required: true,
			default: () => []
		},
		poFields: {
			type: Array,
			required: false
		}
	},
	computed: {
		/**
		 * @return {Array}
		 */
		headerSwithLinks() {
			return this.expenses.map(({ depName, numExpForDep }) => ({
				depName,
				numExpForDep
			}));
		}
	},
	methods: {
		/**
		 * @param {Object} po
		 * @return {Html}
		 */
		rendCardTitle(po) {
			return `${po.depName} <span class='badge badge-secondary'>${po.numPoForDep}</span>`;
		},

		/**
		 * @param {String} link
		 */
		handleSwitchChange(link) {
			console.log({ link });
		},

		/**
		 * @param {Object} user
		 * @return {HTML}
		 */
		rendUserCollapseCardTitle(user) {
			return `${user.fullName}<small> - ${user.functionName} (${user.numPoForUser})</small>`;
		},

		/**
		 * @param {Object} item
		 * @return {Emitter}
		 */
		handleValidateItem(id) {
			this.$emit('validate-item', id);
		},

		/**
		 * @param {Object} item
		 * @return {Emitter}
		 */
		handleCardClicked(item) {
			this.$emit('mobile-card-clicked', item);
		}
	}
};
</script>
