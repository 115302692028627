var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card valide-po-card-item--component-wrapper",
      on: { click: _vm.handleCardClicked },
    },
    [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "head" }, [
          _c("span", { class: ["badge badge-" + _vm.item.validatedClass] }, [
            _vm._v(_vm._s(_vm.item.validatedStatus)),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("article", {
              domProps: { innerHTML: _vm._s(_vm.item.description) },
            }),
            _c("div", [
              _c("span", [
                _vm._v(
                  "\n\t\t\t\t\t" + _vm._s(_vm.FormMSG(1, "Amount")) + ": "
                ),
                _c("b", [_vm._v(_vm._s(_vm.rendAmount))]),
              ]),
              _c("br"),
              _c("span", [
                _vm._v(
                  "\n\t\t\t\t\t" + _vm._s(_vm.FormMSG(2, "Supplier")) + ": "
                ),
                _c("b", [_vm._v(_vm._s(_vm.item.supplierName))]),
              ]),
            ]),
            _c(
              "b-button",
              {
                attrs: { variant: "success" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.validateItem.apply(null, arguments)
                  },
                },
              },
              [_c("i", { staticClass: "icon-check" })]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "foot" }, [
          _c("span", {
            staticClass: "date",
            domProps: { innerHTML: _vm._s(_vm.rendRequestDate) },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }